import styles from './words.module.scss';

export type ContextProps = {
  name: string
};

export const Context = ({name}: ContextProps) => {
  return <span className={`${styles.word} ${styles.context}`}>{name}</span>
}

export type TagProps = {
  name: string
};

export const Tag = ({name}: TagProps) => {
  return <span className={`${styles.word} ${styles.tag}`}>{name}</span>
}