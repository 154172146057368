import { PropsWithChildren } from 'react';
import styles from './loading.module.scss';
import { Nimey } from '../logo/nimey';

export const Loading = (props: PropsWithChildren) => {
  return (
      <div className={styles['inner']}>
        <p><Nimey /></p>
        <p className={styles['childs']}>{props.children}</p>
      </div>
  )
}

export const LoadingPage = (props: PropsWithChildren) => {
  return (
    <div className={styles['loading']}>
      <Loading {...props} />
    </div>
  )
}