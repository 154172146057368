import { Todo } from './todo.js';

export type QueryFilter = {
  lineNumber?: number,
  done?: boolean,
  context?: string[],
  tag?: string[],
}


export class TodoTxt {
  protected contextList = new Set<string>();
  protected tagList = new Set<string>();
  protected todos: Todo[]
  constructor(todos: Todo[] = []) {
    this.todos = todos;
    todos.forEach(todo => {
      todo.getContexts().forEach(context => this.contextList.add(context));
      todo.getTags().forEach(tag => this.tagList.add(tag));
    }); 
  }

  static fromString(input: string) {
    const todos : Todo[] = [];
    let counter = 1;
    for(const row of input.split('\n')) {
      let todo = Todo.fromString(counter, row)
      todos.push(todo);
      counter++;
    }

    return new TodoTxt(todos);
  }

  getContexts() {
    return Array.from(this.contextList);
  }

  getTags() {
    return Array.from(this.tagList);
  }

  render(showLineNumbers: boolean = false) {
    const todos = [];
    for(const todo of this.todos) {
      todos[todo.getLineNumber() - 1] = showLineNumbers ? `${todo.getLineNumber().toString().padStart(3)}: ${todo.toString()}` : todo.toString();
    }

    for(let i = 0; i< todos.length; i++) {
      if(!todos[i]) todos[i] = '';
    }

    return todos.join('\n');
  }

  delete(todo: Todo, keepLineNumbers: boolean = false) {
    const origLineNumber = todo.getLineNumber();
    let newTodos : Todo[] = [];
    for(const currentTodo of this.todos) {
      newTodos.push(currentTodo);

      if(currentTodo.getLineNumber() > origLineNumber) {
        currentTodo.setLineNumber(currentTodo.getLineNumber() - 1);
      }
    }

    this.todos = newTodos;
  }

  add(todo: Todo) {
    this.todos.push(todo);
    todo.getContexts().forEach(c => !this.contextList.has(c) && this.contextList.add(c));
    todo.getTags().forEach(t => !this.tagList.has(t) && this.tagList.add(t));
  }

  replaceTodo(todo: Todo) {
    this.todos = this.todos.map(t => t.getLineNumber() === todo.getLineNumber() ? todo : t);
  }

  insert(lineNumber: number, todo: Todo) {
    if(lineNumber >= this.getNextLineNumber()) {
      const newTodos = [...this.todos.filter(t => t != todo), todo];

      this.todos = newTodos.map((t, i) => {
        t.setLineNumber(i + 1);
        return t;
      });
      return;
    }
    const newTodos : Todo[] = [];
    for(const t of this.todos) {
      if(t.getLineNumber() === lineNumber) {
        newTodos.push(todo);
      }

      if(todo !== t) {
        newTodos.push(t)
      };
    }


    this.todos = newTodos.map((t, i) => {
      console.log(i, t.toString())
      t.setLineNumber(i + 1);
      return t;
    });
  }

  getNextLineNumber() {
    return Math.max(...this.todos.map(t => t.getLineNumber())) + 1;
  }

  queryOne(filter: QueryFilter): Todo | undefined {
    return this.todos.find(todo => todo.matchFilter(filter));
  }

  query(filter: QueryFilter = {}): Todo[] {
    return this.todos.filter(todo => todo.matchFilter(filter));
  }
}