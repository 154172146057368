import React from 'react';
export const Nimey = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78mm"
      height="23mm"
      viewBox="0 0 78 23"
    >
      <g strokeWidth="0.265">
        <path
          fill="#500"
          d="M86.775 137.7q-.459 0-.688-.252-.23-.252-.23-.711v-9.562q0-.459.23-.688.23-.252.665-.252.436 0 .665.252.252.23.252.688v1.972l-.252-.275q.482-1.33 1.56-1.995 1.1-.688 2.522-.688 1.33 0 2.201.481.894.482 1.33 1.468.436.963.436 2.453v6.146q0 .458-.252.71-.23.253-.665.253-.46 0-.711-.252-.23-.253-.23-.711v-6.03q0-1.56-.619-2.271-.596-.734-1.926-.734-1.536 0-2.454.963-.894.94-.894 2.523v5.549q0 .963-.94.963zM98.741 137.66q-.459 0-.688-.252-.23-.275-.23-.757v-9.379q0-.481.23-.733t.688-.253q.436 0 .688.253t.252.733v9.379q0 .482-.252.757-.23.252-.688.252zm0-13.942q-.573 0-.894-.298-.321-.321-.321-.849 0-.55.32-.825.322-.298.895-.298.596 0 .894.298.321.275.321.825 0 .528-.32.849-.299.298-.895.298z"
          className="nimey-red-fill"
          transform="translate(-64.266 -120.14)"
        ></path>
        <path
          fill="#494949"
          d="M103.05 137.7q-.459 0-.688-.252-.23-.252-.23-.711v-9.562q0-.459.23-.688.23-.252.665-.252.436 0 .665.252.252.23.252.688v2.018l-.252-.321q.436-1.284 1.399-1.972.986-.711 2.339-.711 1.421 0 2.293.688.894.665 1.192 2.086h-.344q.413-1.284 1.468-2.017 1.078-.757 2.5-.757 1.26 0 2.063.481.826.482 1.238 1.468.413.963.413 2.454v6.145q0 .459-.252.71-.23.253-.688.253-.436 0-.688-.252-.23-.252-.23-.711v-6.054q0-1.536-.527-2.247-.527-.734-1.788-.734-1.376 0-2.179.963-.78.94-.78 2.569v5.503q0 .459-.252.71-.229.253-.688.253-.435 0-.687-.252-.23-.252-.23-.711v-6.054q0-1.536-.55-2.247-.527-.734-1.766-.734-1.375 0-2.178.963-.78.94-.78 2.569v5.503q0 .963-.94.963zM125.68 137.75q-1.766 0-3.05-.688-1.261-.71-1.972-1.995-.688-1.284-.688-3.073 0-1.742.688-3.026.688-1.307 1.88-2.041 1.216-.734 2.798-.734 1.124 0 2.018.39.894.367 1.513 1.078.642.71.963 1.72.344 1.008.344 2.27 0 .366-.206.55-.206.16-.596.16h-7.98v-1.215h7.544l-.367.298q0-1.238-.367-2.087-.367-.871-1.077-1.33-.688-.458-1.743-.458-1.17 0-1.995.55-.803.528-1.215 1.49-.413.94-.413 2.202v.137q0 2.11 1.009 3.21 1.032 1.101 2.912 1.101.78 0 1.537-.206.78-.206 1.513-.688.321-.206.573-.183.275 0 .436.16.16.138.206.367.07.207-.046.459-.091.252-.39.435-.756.55-1.788.849-1.032.298-2.04.298zM134.13 141.83q-.344 0-.55-.183-.207-.184-.253-.505-.022-.298.115-.619l1.582-3.554v.802l-4.402-10.227q-.138-.344-.092-.642.046-.298.275-.481.23-.184.642-.184.367 0 .573.16.207.161.367.574l3.76 9.218h-.618l3.806-9.218q.184-.413.39-.573.206-.16.596-.16.367 0 .55.183.207.183.23.481.046.275-.092.62l-5.87 13.574q-.183.413-.413.574-.206.16-.596.16z"
          className="nimey-grey-fill"
          transform="translate(-64.266 -120.14)"
        ></path>
      </g>
      <g fill="none">
        <path
          stroke="#737373"
          strokeWidth="7"
          d="M74.902 196.242s-17.562 20.716.658 44.789c-1.238 10.422-10.433 25.314-4.08 20.952 8.222-5.644 17.576-12.214 18.676-11.767 16.662 6.78 31.556-3.622 31.556-3.622"
          className="nimey-grey-stroke"
          transform="translate(-64.266 -120.14) translate(52.386 86.016) scale(.20427)"
        ></path>
        <g stroke="#500">
          <path
            strokeWidth="7.3"
            d="M115.992 226.902l-.229-26.858"
            className="nimey-red-stroke"
            transform="translate(-64.266 -120.14) translate(52.386 86.016) scale(.20427)"
          ></path>
          <path
            strokeWidth="7"
            d="M116.032 214.592s-2.468-15.336-18.82-11.141c-15.563 3.993-12.59 30.34 4.915 29.744 9.699-.126 14.273-10.217 14.273-10.217"
            className="nimey-red-stroke"
            transform="translate(-64.266 -120.14) translate(52.386 86.016) scale(.20427)"
          ></path>
          <path
            strokeWidth="7"
            d="M80.82 190.642s20.458-15.591 42.936.6c18.13 16.035 14.632 45.735 1.335 45.217-9.816.095-9.204-12.824-9.204-12.824"
            className="nimey-red-stroke"
            transform="translate(-64.266 -120.14) translate(52.386 86.016) scale(.20427)"
          ></path>
        </g>
      </g>
      <style type="text/css">
        {`<![CDATA[
		svg {
			--red: #550000;
			--grey: #737373;
		}
		.nimey-red-stroke {
		  stroke: var(--red);
		}
		.nimey-red-fill {
		  fill: var(--red);
		}
		.nimey-grey-stroke {
		  stroke: var(--grey);
		}
		.nimey-grey-fill {
		  fill: var(--grey);
		}
		]]>`}

      </style>
    </svg>
  );
}