import { Button } from "@nimey/react-ui";
import { Todo } from "@nimey/todo-txt";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdArrowForward } from "react-icons/md";
import { useAutocompleteCallback } from "../hooks/use-autocomplete-callback";
import { useTodo } from "../hooks/use-todo";
import { TodoAction } from "../reducer/todo";
import { AutocompleteInput } from "./autocomplete-input";

import styles from './todo-input.module.scss';

export const TodoInput = () => {
  const [fullLine, setFullLine] = useState<string>('');
  const { state, dispatch } = useTodo();
  const currentContexts = useRef<string[]>(state.filter.context || []);

  const autocomplete = useAutocompleteCallback();

  useEffect(() => {
    currentContexts.current = state.filter.context || [];
  }, [state])

  const onSubmit = useCallback((fullLine: string) => {
    const todo = Todo.fromString(state.todoTxt?.getNextLineNumber() || 0, fullLine);
    if(currentContexts.current) (currentContexts.current || []).forEach(c => todo.addContext(c));
    dispatch({ [TodoAction.ADD_TODO]: { todo } });
    setFullLine('');
  }, [fullLine, state, dispatch]);

  return (
    <div className={styles['todo-input']}>
      <AutocompleteInput value={fullLine} setValue={setFullLine} autocomplete={autocomplete} onSubmit={onSubmit} />
      <Button primary onClick={(e) => {
        onSubmit(fullLine)
      }}><MdArrowForward /></Button>
    </div>
  );
}