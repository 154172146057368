import { PropsWithChildren, useEffect, useState } from 'react';
import { Theme as Themes, ThemeContext } from '../context/theme';

export const Theme = (props: PropsWithChildren) => {
  const [ theme, setTheme ] = useState<Themes>(() => {
    const storedTheme = localStorage.getItem('todo-theme')
    if(storedTheme) return storedTheme as Themes;

    if (window.matchMedia('prefers-color-scheme: dark')) {
      return Themes.DARK;
    }

    return Themes.LIGHT;
  });

  useEffect(() => localStorage.setItem('todo-theme', theme), [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
}