import { QueryFilter, Todo, TodoTxt } from '@nimey/todo-txt';
import { TodoState } from '../context/todo';

export enum TodoAction {
  INIT_FROM_FILE_CONTENT = 'INIT_FROM_FILE_CONTENT',
  ADD_TODO = 'ADD_TODO',
  REPLACE_TODO = 'REPLACE_TODO',
  SET_FILTER = 'SET_FILTER',
  MOVE_TODO = 'MOVE_TODO',
};

export type ActionType = {
  [TodoAction.INIT_FROM_FILE_CONTENT]?: { fileContent: string },
  [TodoAction.ADD_TODO]?: { todo: Todo }
  [TodoAction.REPLACE_TODO]?: { todo: Todo },
  [TodoAction.SET_FILTER]?: { filter: QueryFilter },
  [TodoAction.MOVE_TODO]?: {pos?: number, todo: Todo},
}

const updateTodos = (todoTxt: TodoTxt, filter: QueryFilter) => {
  return {
    todos: (todoTxt.query(filter) || []).reverse(),
    contexts: todoTxt?.getContexts() || [],
    tags: todoTxt.getTags() || [],
  };
}
export const getTodoReducer = (update: (file: string, message: string, content: string) => Promise<void>) => {
  return (state: TodoState, action: ActionType) => {
    if (action[TodoAction.INIT_FROM_FILE_CONTENT]) {
      const fileContent = action[TodoAction.INIT_FROM_FILE_CONTENT]?.fileContent as string;
  
      state.todoTxt = TodoTxt.fromString(fileContent);
      state = {...state, ...updateTodos(state.todoTxt, state.filter)}
    }
  
    if(action[TodoAction.ADD_TODO]) {
      const todo = action[TodoAction.ADD_TODO]?.todo as Todo;
      state.todoTxt?.add(todo);
      state = {...state, ...updateTodos(state.todoTxt!, state.filter)}
      if(state.todoTxt) {
        update(state.filename!, `added todo "${todo.getTitle()}"`, state.todoTxt.render());
      }
    }

    if(action[TodoAction.REPLACE_TODO]) {
      const todo = action[TodoAction.REPLACE_TODO]?.todo as Todo;
      state.todoTxt?.replaceTodo(todo);
      state = {...state, ...updateTodos(state.todoTxt!, state.filter)}
      if(state.todoTxt)
        update(state.filename!, `updated todo "${todo.getTitle()}"`, state.todoTxt.render());
    }

    if(action[TodoAction.SET_FILTER]) {
      state.filter = action[TodoAction.SET_FILTER]?.filter || {};

      state = {...state, ...updateTodos(state.todoTxt!, state.filter)}
    }

    if(action[TodoAction.MOVE_TODO]) {
      const pos = action[TodoAction.MOVE_TODO]!.pos;
      const todo = action[TodoAction.MOVE_TODO]!.todo;
      const todoTxt = state.todoTxt!;
      todoTxt.insert(pos || todoTxt.getNextLineNumber(), todo)

      state = {...state, ...updateTodos(todoTxt, state.filter)}
      if(state.todoTxt) {
        update(state.filename!, `rearranged todos`, state.todoTxt.render());
      }
    }
    return {...state};
  }
}
