import React, { useEffect, useRef } from "react";

import styles from './autocomplete-input.module.scss'
import { useTranslation } from 'react-i18next';

export type AutocompleteInputProps = {
  value: string;
  setValue: (value: string) => void,
  autocomplete: (value: string, currentWord: string) => string[],
  onSubmit: (value: string) => void
}
export const AutocompleteInput = (props: AutocompleteInputProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLDivElement>(null);
  const suggestionRef = useRef<HTMLUListElement>(null);
  const clearComplection = () => {
    inputRef.current!.querySelectorAll(`span.${styles['autocomplete']}`).forEach(span => span.parentNode!.removeChild(span));
    suggestionRef.current!.innerHTML = '';
  }
  const addAutocompletion = (value: string, lastWord: string) => {
    if(!inputRef.current) return;    
    const selection = window.getSelection() as Selection;
    const span = document.createElement('span');
    span.addEventListener('mousedown', event => event.preventDefault());
    const onClick = (event: Event) =>  {
      event.preventDefault();
      
      const selection = window.getSelection() as Selection;
      const range = selection.getRangeAt(0);

      const suggestion = (event.target as HTMLLIElement).innerText || '';
      const newText = document.createTextNode(`${suggestion}`)
      range.insertNode(newText);
      clearComplection();

      selection.setPosition(newText, suggestion.length)
    }
    span.addEventListener('touchstart', onClick)
    span.addEventListener('click', onClick)
    span.classList.add(styles['autocomplete'])
    const regex = new RegExp(`^${lastWord.replace('+', '\\+')}`)
    span.innerText = value.replace(regex, '');
    const div = inputRef.current as HTMLDivElement;
    const offset = Array.prototype.indexOf.call(div.childNodes, selection.focusNode!.parentNode);
    if(div.childNodes[offset + 1]) {
      div.insertBefore(span, div.childNodes[offset + 1]);
    } else {
      div.appendChild(span);
    }
  }

  const showSuggestions = (currentWord: string, suggestions: string[], range: Range) => {
    const selection = window.getSelection() as Selection;
    const ul = suggestionRef.current!;
    const rect = range.getBoundingClientRect();
    const parentRect = (selection.focusNode?.parentNode as HTMLSpanElement).getBoundingClientRect();
    ul.style.left = `${rect.x - (rect.x - parentRect.x)}px`;
    ul.style.top = `${rect.y}px`;
    let first = true;
    suggestions.forEach((suggestion) => {
      const li = document.createElement('li');

      if(first) {
        li.classList.add(styles['active'])
        first = false;
      }

      const prefixSpan = document.createElement('span');
      prefixSpan.classList.add(styles['prefix']);
      prefixSpan.innerText = currentWord;

      

      const suggestionSpan = document.createElement('span');
      suggestionSpan.classList.add(styles['suggestion']);
      suggestionSpan.innerText = suggestion.substring(currentWord.length)
      li.addEventListener('mousedown', (event) => event?.preventDefault());
      suggestionSpan.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        
        const selection = window.getSelection() as Selection;
        const range = selection.getRangeAt(0);

        const suggestion = (event.target as HTMLLIElement).innerText || '';
        const newText = document.createTextNode(`${suggestion}`)
        range.insertNode(newText);
        clearComplection();

        selection.setPosition(newText, suggestion.length)
      })

      li.appendChild(prefixSpan);
      li.appendChild(suggestionSpan);
      ul.appendChild(li);
    })
  }

  const onChange = (e: Event) => {
    const event = e as unknown as React.ChangeEvent<HTMLDivElement>;

    const div = document.createElement('div');
    div.innerHTML = event.target.innerHTML;
    div.querySelectorAll(`span.${styles['autocomplete']}`).forEach(span => span.parentNode!.removeChild(span));

    const currentValue = div.innerText.replace(/\s/g,' ');

    const selection = window.getSelection() as Selection;
    const lastWord = selection.anchorNode?.textContent || '';

    clearComplection();

    const completions = props.autocomplete(currentValue, lastWord);
    
    if(completions.length > 0) addAutocompletion(completions[0], lastWord);
    if(completions.length > 1) showSuggestions(lastWord, completions, selection.getRangeAt(0));
    props.setValue(event.target.innerText);

  }

  const onKeyDown = (e: Event) => {
    const event = e as unknown as React.KeyboardEvent
    if(event.key === '@') {
      // @ts-ignore
      var range = window.getSelection().getRangeAt(0);
      const span = document.createElement('span');
      span.innerText = '@';
      range.insertNode(span);
      // @ts-ignore
      window.getSelection()?.setPosition(span, 1)
      event.preventDefault()
      return false;
    }

    if(event.key === '+') {
      // @ts-ignore
      var range = window.getSelection().getRangeAt(0);
      const span = document.createElement('span');
      span.innerText = '+';
      range.insertNode(span);
      // @ts-ignore
      window.getSelection()?.setPosition(span, 1)
      event.preventDefault()
      return false;
    }

    if(event.code === 'Space') {
      let selection = window.getSelection() as Selection;
      var range = selection.getRangeAt(0);
      if (selection.focusNode?.parentNode?.nodeName === 'SPAN') {
        const span = selection.focusNode.parentNode;
        const div = inputRef.current as HTMLDivElement;
        const offset = Array.prototype.indexOf.call(div.childNodes, span);
        const textNode = document.createTextNode("\u00A0");
        if(div.childNodes[offset + 1]) {
          div.insertBefore(textNode, div.childNodes[offset + 1]);
        } else {
          div.appendChild(textNode);
        }
        selection = window.getSelection() as Selection;
        selection.setPosition(textNode, 1);
      }
    }

    if(event.code === 'Tab') {
      event.preventDefault();
      const suggestion = inputRef.current!.querySelector(`span.${styles['autocomplete']}`)?.innerHTML || '';
      const selection = window.getSelection() as Selection;
      const newText = document.createTextNode(`${suggestion}`)
      selection.getRangeAt(0).insertNode(newText);
      clearComplection();

      selection.setPosition(newText, suggestion.length)
    }

    if(event.code === 'ArrowDown') {
      event.preventDefault();
      const lis = Array.from(suggestionRef.current!.querySelectorAll('li'));

      for(const i in lis) {
        const li = lis[i];
        if(li.classList.contains(styles['active'])) {
          let nextLi : Element | undefined;
          if(lis[parseInt(i + 1)]) nextLi = lis[parseInt(i + 1)];
          else nextLi = lis[0];
          if(nextLi) nextLi.classList.add(styles['active']);
          li.classList.remove(styles['active']);
          inputRef.current!.querySelector(`span.${styles['autocomplete']}`)!.innerHTML = nextLi.querySelector(`.${styles['suggestion']}`)!.innerHTML;
          break;
        }
      }
    }

    if(event.code === 'ArrowUp') {
      event.preventDefault();
      const lis = Array.from(suggestionRef.current!.querySelectorAll('li'));

      for(const i in lis) {
        const li = lis[i];
        if(li.classList.contains(styles['active'])) {
          let nextLi : Element | undefined;
          if(parseInt(i) > 0) nextLi = lis[parseInt(i) - 1];
          else nextLi = lis[lis.length - 1];
          if(nextLi) nextLi.classList.add(styles['active']);
          li.classList.remove(styles['active']);
          inputRef.current!.querySelector(`span.${styles['autocomplete']}`)!.innerHTML = nextLi.querySelector(`.${styles['suggestion']}`)!.innerHTML;
          break;
        }
      }
    }

    if(event.code === 'Enter' || event.keyCode == 13) {
      const div = document.createElement('div');
      div.innerHTML = inputRef.current!.innerHTML;
      div.querySelectorAll(`span.${styles['autocomplete']}`).forEach(span => span.parentNode!.removeChild(span));
      props.onSubmit(div.innerText);
      inputRef.current!.innerHTML = '';
      event.preventDefault();
    }
  }

  useEffect(() => {
    if(!inputRef.current) return;

    inputRef.current.innerHTML = '';
    const div = document.createElement('div');
    let words : string[] = [];
    (props.value || '').split(' ').forEach((word) => {
      if(word.startsWith('@') || word.startsWith('+')) {
        div.appendChild(document.createTextNode(words.join(' ')));
        words = [];
        const span = document.createElement('span');
        span.innerText = word;
        div.appendChild(document.createTextNode("\u00A0"));
        div.appendChild(span)
      } else {
        words.push(word)
      }
    })

    if(words.length > 0) div.appendChild(document.createTextNode(words.join(' ')))

    inputRef.current.innerHTML = div.innerHTML;

    inputRef.current.addEventListener('input', onChange);
    inputRef.current.addEventListener('keydown', onKeyDown);

    return () => {
      if(inputRef.current) {
        inputRef.current.removeEventListener('change', onChange);
        inputRef.current.removeEventListener('keydown', onKeyDown);

      }
    }
  }, [])

  return (
    <div style={{width: '100%'}}>
      <div
        placeholder={t('Call Mom @Phone +Family')}
        ref={inputRef}
        contentEditable={true}
        className={styles['input']}
      ></div>
      <ul ref={suggestionRef} className={styles['suggestions']}></ul>
    </div>
  );
}