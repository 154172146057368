import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TodoBookHome } from './pages/todo-book-home';
import { SelectTodoBook } from './pages/select-todo-book';

import styles from './app.module.scss';
import { useTheme } from './hooks/use-theme';
import { NotificationProvider, NotificationRenderer } from '@nimey/react-ui';
import { SettingsProvider } from './components/settings-provider';
import { Home } from './pages/home';
import { AuthProvider } from './components/auth-provider';


const App = () => {
  const { theme } = useTheme();
  return (
    <SettingsProvider scope='base' initialSettings={{foo: 'bar'}}>
      <BrowserRouter basename='/'>
        <NotificationProvider>
          <AuthProvider>
            <div className={`${theme} ${styles['app-wrapper']}`}>
              <Routes>
                <Route path='/todo/:url_key/*' element={<TodoBookHome />} />
                <Route path='/todo/:url_key/' element={<TodoBookHome />} />
                <Route path='/todo/' element={<SelectTodoBook />} />
                <Route path='/' element={<Home />} />
              </Routes>
            </div>
          </AuthProvider>
          <NotificationRenderer />
        </NotificationProvider>
      </BrowserRouter>
    </SettingsProvider>
  )
}

export default App;