import { LogEntry } from '../components/git.js';
import { GitState, GitStatus } from '../context/git';

export enum GitAction {
  SET_STATUS,
  SET_DIR,
  SET_LOG,
};

export type ActionType = {
  type: GitAction;
  status?: GitStatus;
  dir?: string;
  log?: LogEntry[];
}

export const gitReducer = (origState: GitState, action: ActionType) => {
  const state = {...origState};
  switch(action.type) {
    case GitAction.SET_STATUS:
      if(action.status) state.status = action.status
    break;

    case GitAction.SET_DIR:
      if(action.dir) state.dir = action.dir
    break;

    case GitAction.SET_LOG:
      if(action.log) state.log = action.log
      if(action.log && action.log.length > 0) {
        
        const firstCommit = action.log[0];
        if(firstCommit.local && !firstCommit.remote) {
          state.canPush = true;
        } else state.canPush = false;
  
        if(firstCommit.remote && !firstCommit.local) {
          state.canPull = true;
        } else state.canPull = false;
      }
    break;
  }

  return state;
}