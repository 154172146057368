import { createContext } from 'react';
import { QueryFilter, Todo, TodoTxt } from '@nimey/todo-txt';

export type TodoState = {
  todos: Todo[];
  todoTxt?: TodoTxt;
  contexts: string[];
  tags: string[];
  filename?: string;
  filter: QueryFilter;
};

export const defaultFilter: QueryFilter = {
  done: false,
  context: [],
  tag: [],
}

const initialContext : {state: TodoState, dispatch: any} = {
  state: {
    todos: [],
    contexts: [],
    tags: [],
    filter: {...defaultFilter},
  },
  dispatch: () => ''
};

export const TodoContext = createContext(initialContext);