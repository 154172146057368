import { createContext } from 'react';
import { SettingValueType } from '../components/settings-provider';

export type SettingsContextType = {
  scope: string;
  settings: {[key: string]: SettingValueType};
  setSetting: (key: string, value: SettingValueType) => void;
  getSetting: (key: string) => SettingValueType;
}

export const SettingsContext = createContext<SettingsContextType>({
  scope: 'default',
  settings: {},
  setSetting: (key, value) => undefined,
  getSetting: (key) => undefined
});