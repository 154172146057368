import { PropsWithChildren, useEffect, useReducer, useRef } from 'react'
import { GitContext, GitStatus } from '../context/git';
import { GitAction, gitReducer } from '../reducer/git';

import git, { CommitObject } from 'isomorphic-git';
import http from 'isomorphic-git/http/web';
import LightningFS from '@isomorphic-git/lightning-fs';
import { joinPath } from '../hooks/use-git-ls';
import { LoadingPage } from './ui/loading';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useDebounce } from '../hooks/use-debounce';

const fs = new LightningFS('repos')
const pfs = fs.promises
const corsProxy = 'https://cors.isomorphic-git.org';

export type GitProps = {
  url: string;
  url_key: string;
}

export type LogEntry = {oid: string, commit: CommitObject, local: boolean, remote: boolean};

export const Git = (props: PropsWithChildren<GitProps>) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const debouncePush = useDebounce(3000);

  const [ state, dispatch ] = useReducer(gitReducer, {
    status: GitStatus.INIT,
    dir: `/${props.url_key}`,
    branch: 'main',
    log: [],
    canPull: false,
    canPush: false,
  });

  // clone repo
  useEffect(() => {
    const clone = async () => {
      dispatch({type: GitAction.SET_STATUS, status: GitStatus.CLONING});
      try {
        await pfs.mkdir(state.dir);
      } catch(e) {}
      await git.clone({
        fs, http, dir: state.dir, ref: state.branch, url: props.url,
        onAuth: (url) => ({
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`
          }
        })
      });
      dispatch({type: GitAction.SET_STATUS, status: GitStatus.READY});
    }
    clone();
  }, [])

  const update = async (file: string, message: string, content: string) => {
    await pfs.writeFile(joinPath(state.dir, file), content);

    await git.add({ fs, dir: state.dir, filepath: file })
    await git.commit({ fs, dir: state.dir, message, author: {name: 'nimey-todo', email: 'todo@nimey.de'} })
    debouncePush(async () => {
      await git.push({
        fs, http, dir: state.dir,
        onAuth: (url) => ({
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`
          }
        })
      });
      updateLog()
    })
  }

  const updateLog = async () => {
    const logLocal = await git.log({ fs, dir: state.dir, ref: 'main', depth: 30 })
    const logRemote = await git.log({ fs, dir: state.dir, ref: 'origin/main', depth: 30})

    const bothCommits : {[oid: string]: LogEntry} = logLocal.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.oid]: {
          oid: cur.oid,
          commit: cur.commit,
          local: true,
          remote: false
        },
      }
    }, {});


    for(const entry of logRemote) {
      if(bothCommits[entry.oid]) {
        bothCommits[entry.oid].remote = true;
      } else {
        bothCommits[entry.oid] = {
          oid: entry.oid,
          commit: entry.commit,
          local: false,
          remote: true,
        }
      }
    }

    const sorted = Array.from(Object.values(bothCommits)).sort((a, b) => b.commit.committer.timestamp - a.commit.committer.timestamp).slice(0, 10)

    dispatch({type: GitAction.SET_LOG, log: sorted})
  }

  const reset = async () => {
    // @ts-ignore
    pfs.unlink(state.dir)//.then(() => window.location.reload())
  };

  const fetch = async () => {
    const remoteLog = await git.log({ fs, dir: state.dir, depth: 1, ref: 'origin/main' });
    const result = await git.fetch({
      fs, http, dir: state.dir, url: props.url,
      onAuth: (url) => ({
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`
        }
      })
    });


    if(!(remoteLog[0] && result.fetchHead === remoteLog[0].oid)) {
      updateLog()
    }
  }

  const autofetchTimer = useRef<number>();
  useEffect(() => {
    autofetchTimer.current = window.setInterval(fetch, 300 * 1000);

    return () => {
      if(autofetchTimer.current) window.clearInterval(autofetchTimer.current);
    }
  }, [])

  const pull = async () => {
    await git.pull({
      fs, http, dir: state.dir, url: props.url,
      author: {name: 'nimey-todo', email: 'todo@nimey.de'},
      onAuth: (url) => ({
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`
        }
      })
    });

    updateLog();
  }

  const push = async () => {
    await git.push({
      fs, http, dir: state.dir, url: props.url, ref: 'main',
      onAuth: (url) => ({
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`
        }
      })
    });

    updateLog();
  }

  if(state.status !== GitStatus.READY) return <LoadingPage>{t('updating repo')}</LoadingPage>

  return (
    <GitContext.Provider value={{state, dispatch, update, updateLog, fetch, pull, push, reset}}>
      {props.children}
    </GitContext.Provider>
  );
}