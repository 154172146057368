import styles from './pages.module.scss';

export const Error = (props: {message: string}) => {
  return (
    <div className={`${styles['page-wrapper']} ${styles['select-repo']}`}>
      <div className={styles['container']}>
        <h1>Es ist ein Fehler aufgetreten!</h1>
        <p>{ props.message }</p>
      </div>
    </div>
  );
}