import { Todo } from '@nimey/todo-txt';
import { Fragment } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { useTodo } from '../hooks/use-todo';
import { TodoAction } from '../reducer/todo';
import { TodoCard } from './todo-card';
import { TodoInput } from './todo-input';

import styles from './todo-list.module.scss';

const DoneZone = () => {
  const { dispatch } = useTodo();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'todo-card',
    drop: (todo: Todo) => {
      todo.setIsDone(true);
      dispatch({[TodoAction.REPLACE_TODO]: {todo}})
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  return (
    <div ref={drop} style={{
      padding: 10,
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid white',
      display: canDrop ? 'flex' : 'none',
      backgroundColor: isOver ? 'var(--drop-zone-color-hover)' : 'var(--drop-zone-color)',
    }}>DONE</div>
  );
}

const PositionZone = ({pos}: {pos?: number}) => {
  const { dispatch } = useTodo();
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: 'todo-card',
    drop: (todo: Todo) => {
      dispatch({[TodoAction.MOVE_TODO]: {pos, todo}})
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  return (
    <div style={{
      visibility: canDrop ? 'visible' : 'hidden',
      position: 'relative',
      height: '.25em',
      pointerEvents: canDrop ? 'initial' : 'none',
    }}>
    <div ref={drop} style={{
      position: 'absolute',
      backgroundColor: 'transparent',
      top: '-2em',
      bottom: '-2em',
      width: '100%',
      display: 'flex',
      opacity: .75,
      alignItems: 'center'
    }}>
      <div style={{
        backgroundColor: isOver ? 'var(--drop-zone-color-hover)' : 'var(--drop-zone-color)',
        height: '.5em',
        width: '100%'
      }}
      />
    </div>
    </div>
  );
}

export const TodoList = () => {
  const { state } = useTodo();
  const { t } = useTranslation();

  const todos = state.todos || [];
  const filteredTodos = todos.filter(todo => todo.getFullLine() !== '');

  return (
    <div>
      <ul className={styles['todo-list']}>
        <li>
          <TodoInput />
        </li>
        <li><PositionZone /></li>
        {filteredTodos.map(todo => (
          <Fragment key={todo.getLineNumber()}>
            <li>
              <TodoCard {...{todo}} />
            </li>
            <li key={`${todo.getLineNumber()}-drop`}><PositionZone pos={todo.getLineNumber()} /></li>
          </Fragment>
        ))}
      </ul>
      {filteredTodos.length === 0 ? (
        <center>{t('no todo found. everything is done')}</center>
      ) : ''}
      <DoneZone />
    </div>
  );
}