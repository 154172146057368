import { createContext } from 'react';

export enum Theme {
  DARK = 'dark',
  LIGHT = 'light',
}

export const ThemeContext = createContext<{theme: Theme, setTheme: (theme: Theme) => void}>({
  theme: Theme.DARK,
  setTheme: (theme: Theme) => {},
})