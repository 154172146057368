import { Button } from '@nimey/react-ui';
import { Footer } from '../components/ui/footer';
import styles from './pages.module.scss';
import { Link } from 'react-router-dom';
export const Home = () => {
  return (
    <div className={`${styles['page-wrapper']} ${styles['home']}`}>
      <div className={styles['container']}>
        <h1>Noch eine ToDo App</h1>
        <p>Man sollte denken, es gibt schon genug Apps dieser Art. Aber dies ist meine Version, die zu meinen Anforderungen passt</p>
        <p><Link to='/todo'><Button primary>Jetzt Aufgaben erledigen</Button></Link></p>
      </div>
      <div className={styles['footer-outer']}><Footer /></div>
    </div>
  );
  
}