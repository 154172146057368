import { Todo } from '../todo.js';

const ContextRegex = '(@(?<context>[a-zA-Z0-9-]+))';
const TagRegex = '(\\+(?<tag>[a-zA-Z0-9-]+))';
const PrioRegex = '(\\((?<prio>[A-Z])\\))';
const KeyValueRegex = '(?<keyValue>[a-zA-Z]+:[0-9a-zA-Z-.]+)';
const DoneRegex = '(^(?<done>x))';
const fullRegexString = `${[DoneRegex, ContextRegex, TagRegex, PrioRegex, KeyValueRegex].join('|')}`;

const ToDoRegExp = new RegExp(fullRegexString, 'g');

export const createTodo = (lineNumber: number, input: string): Todo => {
  const data : {[key: string]: string[]} = {
    prio: [],
    tag: [],
    context: [],
    keyValue: [],
  }

  let title = input;
  let done = false;
  const matches = input.matchAll(ToDoRegExp);
  for(const match of Array.from(matches) || []) {
    let groups = match.groups || {};
    for(const key of Object.keys(match.groups || {})) {
      if(groups.done === 'x') {
        done = true;
        title = title.replace(/^x /, '');
      }

      if(groups[key] && data[key]) {
        data[key].push(groups[key]);
        switch(key) {
          case 'tag':      title = title.replace(`+${groups[key]}`, ''); break;
          case 'context':  title = title.replace(`@${groups[key]}`, ''); break;
          case 'keyValue': title = title.replace(`${groups[key]}`, ''); break;
          case 'prio':     title = title.replace(`(${groups[key]})`, ''); break;
        }
      }
    }
  }
  
  title = title.replace(/\s+/g, ' ').trim();

  const todo = new Todo(lineNumber, input, {
    done,
    prio: data.prio ? data.prio[0] : undefined,
    tags: data.tag,
    contexts: data.context,
    title,
    keyValue: (data.keyValue || []).reduce((acc, cur) => {
      const matches = cur.match(/(?<key>.*):(?<value>.*)/);
      if(!matches?.groups) return acc;

      return {
        ...acc,
        [matches.groups.key]: matches.groups.value
      };
    }, {})
  });
  return todo;
}