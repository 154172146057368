import { Fragment, PropsWithChildren } from 'react';
import { useAuth, AuthProvider as OidcProvider } from 'react-oidc-context';
import { config } from '../config';

const oidcConfig = {
  authority: 'https://git.nikolas-meyer.de/',
  client_id: '8f5320c4-4741-44ae-bc40-8acc8a8dd57a',
  redirect_uri: config.redirectUrl,
  scope: 'openid respository user email username profile'
}


export const AuthProviderInner = (props: PropsWithChildren) => {
  const auth = useAuth();
    return <Fragment>{props.children}</Fragment>
    // switch (auth.activeNavigator) {
    //     case "signinSilent":
    //         return <div>Signing you in...</div>;
    //     case "signoutRedirect":
    //         return <div>Signing you out...</div>;
    // }

    // if (auth.isLoading) {
    //     return <div>Loading...</div>;
    // }

    // if (auth.error) {
    //     return <div>Oops... {auth.error.message}</div>;
    // }

    // if (auth.isAuthenticated) {
    //     return <Fragment>{props.children}</Fragment>;
    // }

    // return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

export const AuthProvider= (props: PropsWithChildren) => {
  return (
    <OidcProvider {...oidcConfig} >
      <AuthProviderInner>
        {props.children}
      </AuthProviderInner>
    </OidcProvider>
  )
}
