import { useRef } from 'react';
import { Route, Routes, useParams } from 'react-router'
import { Footer } from '../components/ui/footer';
import { Loading } from '../components/ui/loading';
import { Sidebar } from '../components/sidebar';
import { Todo } from '../components/todo';
import { TodoList } from '../components/todo-list';

import styles from './pages.module.scss';

import { DndProvider, useDragLayer } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { TodoCardRenderer } from '../components/todo-card';
import { calculatePointerPosition } from '../util/offset';

import todoStyles from '../components/todo-card.module.scss';
import { SettingsProvider } from '../components/settings-provider';

const Preview = () => {
  const child = useRef<HTMLDivElement>(null);
  const props = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    monitor,
    currentOffset: monitor.getClientOffset(),
    pos: calculatePointerPosition(monitor, child),
  }));

  return (
    <div ref={child} className={todoStyles['preview']} style={{
      pointerEvents: 'none',
      display: props.isDragging ? 'block' : 'none',
      left: props.pos?.x || 0,
      top: props.pos?.y || 0,
    }}>
      {(props.isDragging && props.item ? (<TodoCardRenderer todo={props.item} />) : '')}
    </div>
  )
}

const isTouch = () => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

const backend = isTouch() ? TouchBackend : HTML5Backend;

export const TodoFile = () => {
  const { file } = useParams();

  if(!file) return <Loading />

  return (
    <SettingsProvider scope={`file-${file}`} initialSettings={{}}>
      <Todo filename={file}>
        <DndProvider backend={backend}>
          <div className={`${styles['page-wrapper']} ${styles['todo-file']}`}>
            <div className={styles['todo-renderer']}>
              <Routes>
                <Route path='/' element={<TodoList />} />
              </Routes>
            </div>
            <Sidebar />
          </div>
          <Footer />
          {isTouch() ? <Preview /> : ''}
        </DndProvider>
      </Todo>
    </SettingsProvider>
  );
}