import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useTodo } from '../hooks/use-todo';
import { TodoAction } from '../reducer/todo';
import styles from './sidebar.module.scss';
import { Context, Tag } from './words';

export const Sidebar = () => {
  const { state, dispatch } = useTodo();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={styles['background']} onClick={() => document.body.classList.toggle('sidebar-visible')} />
      <aside className={styles.sidebar}>
        <label><input 
          type='checkbox'
          checked={state.filter.done !== false}
          onChange={(e) => {
            dispatch({ [TodoAction.SET_FILTER]: {filter: { ...state.filter, done: e.target.checked ? undefined: false }} })
          }}
        /> {t('show done')}</label>

        <hr />

        <p><strong>{t('context')}</strong></p>
        <ul className={styles['context-filter']}>
          {state.contexts.map(context => (
            <li key={context}>
              <label>
                <input
                  type='checkbox'
                  checked={state.filter.context?.includes(context)}
                  onChange={(e) => {
                    const newContextFilter = [...(state.filter.context || [])].filter(c => c !== context);
                    if(e.target.checked) newContextFilter.push(context);
                    dispatch({ [TodoAction.SET_FILTER]: { filter: {
                      ...state.filter,
                      context: newContextFilter
                    }}})
                  }}
                />
                <Context name={context} />
              </label>
            </li>
          ))}
        </ul>

        <p><strong>{t('tags')}</strong></p>
        <ul className={styles['tag-filter']}>
          {state.tags.map(tag => (
            <li key={tag}>
              <label>
                <input
                  type='checkbox'
                  checked={state.filter.tag?.includes(tag)}
                  onChange={(e) => {
                    const newTagFilter = [...(state.filter.tag || [])].filter(t => t !== tag);
                    if(e.target.checked) newTagFilter.push(tag);
                    dispatch({ [TodoAction.SET_FILTER]: { filter: {
                      ...state.filter,
                      tag: newTagFilter
                    }}})
                  }}
                />
                <Tag name={tag} />
              </label>
            </li>
          ))}
        </ul>
      </aside>
    </Fragment>
  );
}