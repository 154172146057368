import { Button } from '@nimey/react-ui';
import { Fragment } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Footer } from '../components/ui/footer';
import { useRepos } from '../hooks/use-repo';
import styles from './pages.module.scss';
import { useTranslation } from 'react-i18next';

export const SelectTodoBook = () => {
  const { repos } = useRepos();
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={`${styles['page-wrapper']} ${styles['select-repo']}`}>
        <div className={styles['container']}>
          <h1>{t('choose repository')}</h1>
          <ul>
            {repos.map(repo => (
              <li key={repo.name} title={repo.name}>
                <Link to={`/todo/${repo.url_key}`}>
                  <Button primary>
                    <span>{repo.alias}</span>
                    <span><MdArrowForward /></span>
                  </Button>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </Fragment>

  );
}