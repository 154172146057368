import { Button } from '@nimey/react-ui';
import { Fragment } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { Route, Routes, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Footer } from '../components/ui/footer';
import { InitRepo } from '../components/init-repo';
import { useRepos } from '../hooks/use-repo';
import { useTodoFiles } from '../hooks/use-todo-files';
import { Error } from './error';

import styles from './pages.module.scss';
import { TodoFile } from './todo-file';

import { useTranslation } from 'react-i18next';
import { SettingsProvider } from '../components/settings-provider';

const Home = () => {
  const files = useTodoFiles();
  const params = useParams();

  const { t } = useTranslation()
  
  return (
    <Fragment>
      <div className={`${styles['page-wrapper']} ${styles['select-todo-file']}`}>
        <div className={styles.container}>
          <h1>{t('choose todofile')}</h1>
          <ul>
            {(files || []).map(file => (
              <li key={file}>
                <Link to={`/todo/${params.url_key}${file}`}>
                  <Button>
                    {file} <MdArrowForward />
                  </Button>
                </Link>
              </li>
            ))}
          </ul>
        </div> 
      </div>
      <Footer />
    </Fragment>
  );
}

export const TodoBookHome = () => {
  const { repos } = useRepos();
  const { url_key } = useParams()
  const repo = repos.find(r => r.url_key === url_key);

  if(!repo) {
    return <Error message={'Dieses Repo habe ich leider nicht gefunden'} />
  }
  return (
    <InitRepo repo={repo}>
      <SettingsProvider scope={`repo-${repo.alias}`} initialSettings={{}}>
        <Routes>
          <Route path='/:file' element={<TodoFile />} />
          <Route path='/' element={<Home />} />
        </Routes>
      </SettingsProvider>
    </InitRepo>
  );
}