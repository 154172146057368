import { MdBrightnessHigh, MdDarkMode } from "react-icons/md";

import styles from './dark-mode-toggle.module.scss';

export type DarkModeToggleProps = {
  mode: 'DARK' | 'LIGHT';
  setMode: (mode: 'DARK' | 'LIGHT') => void;
}
export const DarkModeToggle = ({ mode, setMode }: DarkModeToggleProps) => {
  return (
    <label className={styles['toggle']}>
      <input type='checkbox' checked={mode === 'DARK'} onChange={(e) => {
        setMode(e.target.checked ? 'DARK' : 'LIGHT');
      }} />
      <div className={styles.inner}>
        <div className={styles.dark}><MdDarkMode /></div>
        <div className={styles.light}><MdBrightnessHigh /></div>
      </div>
    </label>
  );
}