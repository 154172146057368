import { QueryFilter } from './todo-txt.js';
import { createTodo } from './util/create-todo.js';

export type KeyValueType = {[key: string]: string};

export class Todo {
  protected lineNumber: number;
  protected fullLine: string;
  protected done: boolean;
  protected tags : Set<string> = new Set();
  protected contexts : Set<string> = new Set();
  protected prio : string | undefined;
  protected title : string;
  protected keyValue: Map<string, string> = new Map();

  constructor(lineNumber: number, fullLine: string, info: {done?: boolean, tags?: string[], contexts?: string[], prio?: string, keyValue?: KeyValueType, title?: string} = {}) {
    this.lineNumber = lineNumber;
    this.fullLine = fullLine;
    this.done = info.done || false;
    this.tags = new Set(info.tags || []);
    this.contexts = new Set(info.contexts || []);
    this.prio = info.prio;
    this.title = info.title || '';
    this.keyValue = new Map(Object.entries(info.keyValue || {}));
  }

  static fromString(lineNumber: number, input: string) : Todo {
    return createTodo(lineNumber, input);
  }

  getTitle() {
    return this.title;
  }

  getContexts() {
    return this.contexts;
  }

  getTags() {
    return this.tags;
  }

  isDone() {
    return this.done;
  }

  setIsDone(value: boolean) {
    this.done = value;
    if(value) {
      this.fullLine = `x ${this.fullLine}`;
    } else {
      this.fullLine = this.fullLine.replace(/^x /, '');
    }
  }

  getFullLine() {
    return this.fullLine;
  }

  getLineNumber() {
    return this.lineNumber;
  }

  setLineNumber(lineNumber: number) {
    this.lineNumber = lineNumber;
  }

  toString() {
    return this.fullLine;
  }

  _clearWhitespaces() {
    this.fullLine = this.fullLine.replace(/\s+/g, ' ');
  }

  addTag(...tags: string[]) {
    this.fullLine = `${this.fullLine} ${tags.map(t => `+${t}`).join(' ')}`;
    tags.forEach(tag => this.tags.add(tag));
    this._clearWhitespaces();
  }

  removeTag(...tags: string[]) {
    for(const tag of tags) {
      this.fullLine = this.fullLine.replace(`+${tag}`, '');
    }

    tags.forEach(tag => this.contexts.delete(tag));
    this._clearWhitespaces();
  }

  replaceTag(find: string, replace: string) {
    if(!this.tags.has(find)) throw new Error('tag not found');
    this.fullLine = this.fullLine.replace(`+${find}`, `+${replace}`);
    this.tags.delete(find);
    this.tags.add(replace);
    this._clearWhitespaces();
  }

  addContext(...contexts: string[]) {
    let filteredContexts = contexts.filter(c => !this.contexts.has(c));
    console.log(filteredContexts)
    this.fullLine = `${this.fullLine} ${filteredContexts.map(t => `@${t}`).join(' ')}`;
    filteredContexts.forEach(context => this.contexts.add(context));
    this._clearWhitespaces();
  }

  removeContext(...contexts: string[]) {
    for(const context of contexts) {
      this.fullLine = this.fullLine.replace(`@${context}`, '');
    }

    contexts.forEach(context => this.contexts.delete(context));
    this._clearWhitespaces();
  }

  replaceContext(find: string, replace: string) {
    if(!this.contexts.has(find)) throw new Error('context not found');
    this.fullLine = this.fullLine.replace(`@${find}`, `@${replace}`);
    this.contexts.delete(find);
    this.contexts.add(replace);
    this._clearWhitespaces();
  }

  hasKeyValue(key: string) {
    return this.keyValue.has(key);
  }

  setKeyValue(key: string, value: string) {
    if(this.hasKeyValue(key)) {
      this.fullLine = this.fullLine.replace(`${key}:${this.keyValue.get(key)}`, `${key}:${value}`);
    } else {
      this.fullLine = `${this.fullLine} ${key}:${value}`
    }

    this.keyValue.set(key, value);
    this._clearWhitespaces();
  }

  matchFilter(filter: QueryFilter) {
    if(typeof filter.done === 'boolean') {
      if(this.done !== filter.done) return false;
    }
    if(typeof filter.lineNumber === 'number') return this.lineNumber === filter.lineNumber;
    
    if(filter.context && filter.context.length > 0 && !filter.context.find(c => this.contexts.has(c))) return false;
    
    if(filter.tag && filter.tag.length > 0 && !filter.tag.find(c => this.tags.has(c))) return false;

    return true;
  }

}