import { useState } from 'react'

const LOCAL_STORAGE_KEY = 'repos'

export type Repo = {
  name: string;
  url: string;
  alias: string;
  url_key: string;
};

const initialRepos = [
  {
    name: 'git.nikolas-meyer.de/nikolas/todo.git',
    url: 'https://git.nikolas-meyer.de/nikolas/todo.git',
    alias: 'Nikolas',
    url_key: 'nikolas',
  }
];

export const useRepos = () => {
  const [ repos, setRepos ] = useState<Repo[]>(() => {
    const str = localStorage.getItem(LOCAL_STORAGE_KEY);
    if(str) return JSON.parse(str);
    return initialRepos;
  });

  const addCallback = (repo: Repo) => {
    setRepos([...repos, repo]);

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(repos));
  }

  return  {
    repos: repos,
    add: addCallback,
  }
}