import { Button, Confirm, Modal } from '@nimey/react-ui'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { SiGitea} from 'react-icons/si';

import styles from './git-status.module.scss';
import { MdArrowDownward, MdArrowUpward, MdReplay } from 'react-icons/md';
import { useGit } from '../hooks/use-git';
import { useTranslation } from 'react-i18next';
import { LogEntry } from './git.js';

const LogEntryRenderer = ({ entry }: { entry: LogEntry }) => {
  const { oid, commit, remote, local } = entry;
  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <pre>
        <span>{oid.substring(0, 8)} {commit.author.name} {commit.message}</span>
      </pre>
      <pre>
        {local ? 'L' : ' '} {remote ? 'R' : ' '}
      </pre>
    </div>
  );
}

export const GitModal = ({open, setOpen}: {open: boolean, setOpen: (v: boolean) => void}) => {
  const git = useGit();
  const { t } = useTranslation();
  useEffect(() => {
    git.updateLog();
  }, [open]);


  return (
    <Modal isOpen={open} close={() => setOpen(false)}>
      <div className={styles['status-modal']}>
        <header>
          <h1>GIT - Status</h1>
        </header>
        <section className={styles['log']}>
          <h2>{t('history')}</h2>
          <ul>
            {git.state.log.map((entry) => (
              <li key={entry.oid}><LogEntryRenderer {...{entry}} /></li>
            ))}
          </ul>
        </section>
        <footer>
          <div className={styles['actions']}>
            <span title='pull'><Button disabled={!git.state.canPull} onClick={() => git.pull()}><MdArrowDownward /></Button></span>
            <span title='fetch'><Button onClick={() => git.fetch()}><MdReplay /></Button></span>
            <span title='push'><Button disabled={!git.state.canPush} onClick={() => git.push()}><MdArrowUpward /></Button></span>
            <div style={{float: 'right'}}><Confirm message={'Reset Repo'} buttonText='Reset Repo' onConfirm={() => {
              git.reset();
            }} /></div>
          </div>
        </footer>
      </div>
    </Modal>
  );
}

export const GitStatus = () => {
  const [ showModal, setShowModal] = useState<boolean>(false);
  const { state } = useGit();
  return (
    <Fragment>
      <div className={styles.status}>
        <GitModal {...{open: showModal, setOpen: setShowModal}} />
        <Button primary={state.canPull} onClick={() => setShowModal(true)}><SiGitea /></Button>
      </div>
    </Fragment>
  )
}