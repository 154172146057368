import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { SettingsContext } from '../context/settings';

export type ListOrScalar<T> = T | [];

export type SettingValueType = ListOrScalar<string | number | undefined>;

export type SettingsType = {[key: string]: SettingValueType};

export type SettingsProviderProps = {
  scope: string;
  initialSettings: SettingsType
}

export const SettingsProvider = (props: PropsWithChildren<SettingsProviderProps>) => {
  const parentContext = useContext(SettingsContext);
  const [ settings, setSettings ] = useState<SettingsType>(() => {
    const strData = localStorage.getItem(`${props.scope}.settings`);
    if(!strData) return props.initialSettings;

    return JSON.parse(strData);
  });

  useEffect(() => {
    localStorage.setItem(`${props.scope}.settings`, JSON.stringify(settings));
  }, [props.scope, settings]);

  const setSetting = (key: string, value: SettingValueType) => {
    setSettings((current) => ({
      ...current,
      [key]: value
    }))
  }

  const getSetting = (key: string) => {
    if(typeof settings[key] !== 'undefined') return settings[key];
    if(parentContext && parentContext.getSetting) return parentContext.getSetting(key)
  }

  return (
    <SettingsContext.Provider value={{scope: props.scope, settings, setSetting, getSetting}}>
      {props.children}
    </SettingsContext.Provider>
  )
}