import { MdLogout, MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Theme } from '../../context/theme';
import { useTheme } from '../../hooks/use-theme';
import styles from './footer.module.scss';

import { Nimey } from '../logo/nimey'
import { DarkModeToggle } from '../ui/dark-mode-toggle';
import { Button } from '@nimey/react-ui';
import { useAuth } from 'react-oidc-context';
import { Fragment } from 'react';
import { GitStatus } from '../git-status';


const ThemeSelector = () => {
  const { theme, setTheme } = useTheme();
  return (
    <DarkModeToggle
      mode={theme === Theme.DARK ? 'DARK' : 'LIGHT'}
      setMode={(mode) => setTheme(mode === 'DARK' ? Theme.DARK : Theme.LIGHT)}
    />
  );
}

const Account = () => {
  const auth = useAuth();
  if(auth.isAuthenticated) {
    return (
      <Button onClick={() => auth.removeUser()}><MdLogout /></Button>
    );
  }

  return <Fragment />
  
}


export const Footer = () => {
  
  return (
    <div className={styles['footer']}>
      <div className={styles['title']}>
        <Link to='/'><Nimey /></Link>
      </div>
      <div className={styles.actions}>
        <div className={styles['git']}>
          <GitStatus />
        </div>
        <div className={styles['account']}>
          <Account />
        </div>
        <div className={styles['theme-selector']}>
          <ThemeSelector />
        </div>
        
        <div className={styles['menu-trigger']}>
          <div
            style={{padding: '.25em'}}
            onClick={(e) => document.body.classList.toggle('sidebar-visible')}
          ><MdMenu /></div>
        </div>
      </div>
    </div>
  );
}