import { useEffect, useRef } from "react";
import { useTodo } from "./use-todo";

export const useAutocompleteCallback = () => {
  const { state } = useTodo();
  const suggestions = useRef<string[]>([]);

  useEffect(() => {
    suggestions.current = [
      ...state.contexts.map((c) => `@${c}`),
      ...state.tags.map((t) => `+${t}`)
    ];
  }, [state]);


  return (currentValue: string, currentWord: string) => {
    if(!currentWord) return [];
    return suggestions.current.filter((sug) => sug.startsWith(currentWord));
  }
}