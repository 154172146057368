import { useContext, useEffect, useState } from 'react'
import { GitContext } from '../context/git'
import { useGit } from './use-git';

import LightningFS from '@isomorphic-git/lightning-fs';

const fs = new LightningFS('repos')
const pfs = fs.promises

export const joinPath = (...pathElements: string[]) => {
  let first = true;
  let elements = [];
  for(let element of pathElements) {
    if(first) {
      first = false;
      element = element.replace(/\/+$/, '');
    }
    else element = element.replace(/^\/+/, '').replace(/\/+$/, '');
    elements.push(element);
  }

  return elements.join('/');
}

export type FsEntry = {
  type: 'FILE' | 'DIR';
  name: string;
  path: string;
  fullPath: string;
}

export const useGitLs = (basePath: string = '/') => {
  const [ entries, setEntries ] = useState<FsEntry[]>([]);
  const { state } = useGit();

  useEffect(() => {
    const getFiles = async () => {
      const fsBasePath = joinPath(state.dir, basePath);
      const fsEntries = await pfs.readdir(fsBasePath);
      let newEntries : FsEntry[] = [];
      for(const entry of fsEntries) {
        const entryPath = joinPath(fsBasePath, entry);
        const stat = await pfs.stat(entryPath);
        newEntries.push({
          type: stat.type === 'dir' ? 'DIR' : 'FILE',
          name: entry,
          fullPath: entryPath,
          path: joinPath(basePath, entry),
        })
      }

      setEntries(newEntries);
    };
    getFiles();
  }, [ basePath ])


  return { entries }
}