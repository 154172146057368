import { PropsWithChildren, useEffect, useReducer } from 'react';
import { defaultFilter, TodoContext } from '../context/todo';
import { getTodoReducer, TodoAction } from '../reducer/todo';

import LightningFS from '@isomorphic-git/lightning-fs';
import { useGit } from '../hooks/use-git';
import { joinPath } from '../hooks/use-git-ls';

const fs = new LightningFS('repos')
const pfs = fs.promises

export type TodoProps = {
  filename: string
};

export const Todo = (props: PropsWithChildren<TodoProps>) => {
  const { state: gitState, update } = useGit();
  const [ state, dispatch ] = useReducer(getTodoReducer(update), {
    todos: [],
    contexts: [],
    tags: [],
    filename: props.filename,
    filter: {...defaultFilter},
  });

  useEffect(() => {
    const load = async () => {
      const fullPath = joinPath(gitState.dir, props.filename);
      const content = await pfs.readFile(fullPath);
      dispatch({[TodoAction.INIT_FROM_FILE_CONTENT]: { fileContent: content.toString() }});
    };

    load();
  }, [props.filename, gitState.dir, gitState.log]);

  return (
    <TodoContext.Provider value={{ state, dispatch }}>
      {props.children}
    </TodoContext.Provider>
  );
}