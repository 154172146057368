import { useNotify } from '@nimey/react-ui';
import { Todo } from '@nimey/todo-txt';
import { Fragment, useMemo, useState } from 'react';
import { ConnectDragPreview, ConnectDragSource, useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { MdDragHandle } from 'react-icons/md';
import { useAutocompleteCallback } from '../hooks/use-autocomplete-callback';
import { useTodo } from '../hooks/use-todo';
import { TodoAction } from '../reducer/todo';
import { AutocompleteInput } from './autocomplete-input';

import styles from './todo-card.module.scss';
import { Context, Tag } from './words';

export type TodoCardProps = {
  todo: Todo
};

export const TodoTextRenderer = ({text}: {text: string}) => {
  const words = useMemo(() => {
    const parts = text.split(' ');
    let result = [];
    let tmp : string[] = [];
    for(const part of parts) {
      if(part.startsWith('@') || part.startsWith('+')) {
        if(tmp.length > 0) result.push({type: 'text', text: tmp.join(' ')});
        result.push({type: part.startsWith('@') ? 'context' : 'tag', text: part});
        tmp = [];
      } else {
        tmp.push(part)
      }
    }

    if(tmp.length > 0) result.push({type: 'text', text: tmp.join(' ')});
    
    return result;
  }, [text])

  return (
    <Fragment>
      {words.map((word, idx) => (
        <Fragment key={idx}>
          {word.type === 'context' ? <Context name={word.text} /> : ''}
          {word.type === 'tag' ? <Tag name={word.text} /> : ''}
          {word.type === 'text' ? <span>{word.text}</span> : ''}
        </Fragment>
      ))}
    </Fragment>
  );
}

const EditTodo = ({todo, setEdit}: {todo: Todo, setEdit: (value: boolean) => void}) => {
  const { dispatch } = useTodo();
  const autocomplete = useAutocompleteCallback();
  const onSubmit = (value: string) => {
    const newTodo = Todo.fromString(todo.getLineNumber(), value);
    dispatch({[TodoAction.REPLACE_TODO]: {todo: newTodo}});
    setEdit(false);
  }
  return (
    <div style={{width: '100%', display: 'inline-flex'}}>
      <AutocompleteInput value={todo.getFullLine()} setValue={() => ''} onSubmit={onSubmit} autocomplete={autocomplete} />
    </div>
  );
}

export const TodoCardRenderer = ({style, todo, drag, preview}: {style?: {[key: string]: number | string | undefined}, todo: Todo, drag?: ConnectDragSource, preview?: ConnectDragPreview}) =>  {
  const { state, dispatch } = useTodo();
  const [ isEdit, setEdit ] = useState<boolean>(false);

  const notify = useNotify();
  const { t } = useTranslation();

  const setDone = (value: boolean) => {
    const newTodo = Todo.fromString(todo.getLineNumber(), todo.getFullLine());
    newTodo.setIsDone(value);
    todo.setIsDone(value);
    dispatch({ [TodoAction.REPLACE_TODO]: { todo, newTodo } })
    if(!value) return;
    notify({title: t('todo done'), text: todo.getTitle(), actions: [
      {
        text: t('undo'),
        primary: true,
        callback: () => {
          todo.setIsDone(false);
          dispatch({ [TodoAction.REPLACE_TODO]: { todo, newTodo } })
        }
      }
    ]})
  }

  const text = useMemo(() => {
    let t = todo.getFullLine();
    if(state.filter.context && state.filter.context.length === 1) {
      for(const context of state.filter.context) {
        t = t.replace(`@${context}`, '');
      }
    }

    t = t.replace(/\s/g, ' ');
    return t;
  }, [todo, state])


  return (
    <div ref={preview} style={{...style}} className={styles['todo-card']}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <input type='checkbox' checked={todo.isDone()} onChange={(e) => setDone(e.target.checked)} />
        {isEdit ? (<EditTodo todo={todo} setEdit={setEdit} />) : <span onClick={() => setEdit(true)}><TodoTextRenderer text={text} /></span>}
        
      </div>
      <div ref={drag} style={{cursor: 'grab', fontSize: '2em', display: 'flex', alignItems: 'center'}}><MdDragHandle /></div>
    </div>
  );
}

export const TodoCard = (props: TodoCardProps) => {
  const todo = props.todo;

  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: 'todo-card',
      item: todo,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [todo],
  )


  return <TodoCardRenderer {...{todo, drag, preview, style: {opacity}}} />
}