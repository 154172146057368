import { Fragment, PropsWithChildren } from 'react';
import { Repo } from '../hooks/use-repo';
import { Git } from './git';
import { useAuth } from 'react-oidc-context';
import { Button } from '@nimey/react-ui';

export type InitRepoProps = {
  repo: Repo;
};

export const InitRepo = (props: PropsWithChildren<InitRepoProps>) => {
  const { repo } = props;

  const auth = useAuth();

  if(!auth.isAuthenticated) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div><Button onClick={() => {
          auth.signinRedirect();
        }}>login</Button></div>
      </div>
    )
  }

  return (
    <Git {...repo}>
      { props.children }
    </Git>
  );
}