import { createContext } from 'react';
import { LogEntry } from '../components/git.js';

export enum GitStatus {
  INIT = 'init',
  CLONING = 'cloning',
  READY = 'ready',
};

export type GitState = {
  status: GitStatus;
  dir: string;
  branch: string;
  log: LogEntry[];
  canPull: boolean;
  canPush: boolean;
};

export type GitContextType = {
  state: GitState,
  dispatch: any,
  update: (file: string, message: string, content: string) => Promise<void>,
  fetch: () => Promise<void>,
  pull: () => Promise<void>,
  push: () => Promise<void>,
  updateLog: () => Promise<void>
  reset: () => Promise<void>
}

const initialContext : GitContextType = {
  state: {
    status: GitStatus.INIT,
    dir: '/',
    branch: 'main',
    log: [],
    canPull: false,
    canPush: false,
  },
  dispatch: () => '',
  updateLog: () => new Promise(resolve => resolve),
  reset: () => new Promise(resolve => resolve),
  fetch: () => new Promise(resolve => resolve),
  pull: () => new Promise(resolve => resolve),
  push: () => new Promise(resolve => resolve),
  update: async (message: string) => {},
};

export const GitContext = createContext<GitContextType>(initialContext);